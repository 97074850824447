// mutation types
export const UPDATE_SS_CLIENT_ID = "updateSsClientId";
export const SET_SS_CLIENT_ID = "setSsClientId";
export const UPDATE_SS_CLIENT_SECRET = "updateSsClientSecret";
export const SET_SS_CLIENT_SECRET = "setSsClientSecret";
export const UPDATE_SS_ORGANIZATION_ID = "updateSsOrganizationId";
export const SET_SS_ORGANIZATION_ID = "setSsOrganizationId";
export const UPDATE_SS_ACCESS_TOKEN = "updateSsAccessToken";
export const SET_SS_ACCESS_TOKEN = "setSsAccessToken";

const state = {
  satusehat:{
    client_id: null,
    client_secret: null,
    organization_id: null,
    access_token: null,
  }
};

const getters = {
  currentSsClientId(state) {
    return state.satusehat.client_id;
  },

  currentSsClientSecret(state) {
    return state.satusehat.client_secret;
  },

  currentSsOrganizationId(state) {
    return state.satusehat.organization_id;
  },

  currentSsAccessToken(state) {
    return state.satusehat.access_token;
  },

};

const actions = {
  [UPDATE_SS_CLIENT_ID](context, payload) {
    context.commit(SET_SS_CLIENT_ID, payload);
  },
  [UPDATE_SS_CLIENT_SECRET](context, payload) {
    context.commit(SET_SS_CLIENT_SECRET, payload);
  },
  [UPDATE_SS_ORGANIZATION_ID](context, payload) {
    context.commit(SET_SS_ORGANIZATION_ID, payload);
  },
  [UPDATE_SS_ACCESS_TOKEN](context, payload) {
    context.commit(SET_SS_ACCESS_TOKEN, payload);
  },
};

const mutations = {
  [SET_SS_CLIENT_ID](state, payload) {
    state.satusehat.client_id = payload;
  },
  [SET_SS_CLIENT_SECRET](state, payload) {
    state.satusehat.client_secret = payload;
  },
  [SET_SS_ORGANIZATION_ID](state, payload) {
    state.satusehat.organization_id = payload;
  },
  [SET_SS_ACCESS_TOKEN](state, payload) {
    state.satusehat.access_token = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};