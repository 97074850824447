const ID_TOKEN_KEY = "id_token"
const APPOINTMENT_FORM_KEY = "hNVna4uY"

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY)
}

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
  window.localStorage.removeItem("access_right")
  window.localStorage.removeItem("user")
}

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem('user'))
}

export const setUser = (user) => {
  window.localStorage.setItem('user', user)
}

export const setNewInvoice = (new_invoice) => {
  window.localStorage.setItem('new_invoice', new_invoice)
}

export const setConfiguration = (configuration) => {
  window.localStorage.setItem('configuration', configuration)
}

export const getConfiguration = () => {
  return JSON.parse(window.localStorage.getItem('configuration'))
}

export const setAppointmentForm = (form) => {
  window.localStorage.setItem(APPOINTMENT_FORM_KEY, JSON.stringify(form))
}

export const getAppointmentForm = () => {
  return JSON.parse(window.localStorage.getItem(APPOINTMENT_FORM_KEY))  
}

export const removeAppointmentForm = () => {
  window.localStorage.removeItem(APPOINTMENT_FORM_KEY)
}

export const getDbConfig = () => {
  return JSON.parse(window.localStorage.getItem('dbconfiguration'))
}

export const getdbconfigparams = () => {
  return window.localStorage.getItem('dbconfigparams')
}

export const getTenantConfig = () => {
  return JSON.parse(window.localStorage.getItem('tenantconfig'))
}

export const setTokenBridge = (new_invoice) => {
  window.localStorage.setItem('bridge_access_token', new_invoice)
}

export const getTokenBridge = () => {
  return window.localStorage.getItem("bridge_access_token")
}

export const setRefreshTokenBridge = (new_invoice) => {
  window.localStorage.setItem('bridge_refresh_token', new_invoice)
}

export const getRefreshTokenBridge = () => {
  return window.localStorage.getItem("bridge_refresh_token")
}

export const setTokenSatuSehat = (new_invoice) => {
  window.localStorage.setItem('ss_access_token', new_invoice)
}

export const getTokenSatuSehat = () => {
  return window.localStorage.getItem("ss_access_token")
}

export const setSsOrganizationId = (new_invoice) => {
  window.localStorage.setItem('ss_organization_id', new_invoice)
}

export const getSsOrganizationId = () => {
  return window.localStorage.getItem("ss_organization_id")
}

export const setSsSecret = (new_invoice) => {
  window.localStorage.setItem('ss_secret', new_invoice)
}

export const getSsSecret = () => {
  return window.localStorage.getItem("ss_secret")
}

export const setSsId = (new_invoice) => {
  window.localStorage.setItem('ss_id', new_invoice)
}

export const getSsId = () => {
  return window.localStorage.getItem("ss_id")
}

export const setSsLocationDefault = (data) => {
  window.localStorage.setItem('location_default', JSON.stringify(data))
}

export const getSsLocationDefault = () => {
  return JSON.parse(window.localStorage.getItem("location_default"))
}

export default { getToken, saveToken, destroyToken, getUser, setUser, setNewInvoice, 
  setConfiguration, getConfiguration, setAppointmentForm, getAppointmentForm, 
  removeAppointmentForm, getDbConfig, getdbconfigparams, getTenantConfig,
  setTokenBridge, getTokenBridge, setRefreshTokenBridge, getRefreshTokenBridge, setTokenSatuSehat, getTokenSatuSehat,
  setSsOrganizationId, getSsOrganizationId, setSsLocationDefault, getSsLocationDefault,
  setSsId, getSsId, setSsSecret, getSsSecret
}
