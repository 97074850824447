import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

let conf = JSON.parse(window.localStorage.getItem('configuration'))

const router = new Router({
  mode: "hash",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [

        // dashboard
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: {
            // title: 'IONA | Dashboard',
            title: `${conf == null ? "Medhop" : conf.clinic_name} | Dashboard`,
            // access_right: '1001'
          },
        },

        // centraldashboard
        {
          path: "/central-dashboard",
          name: "central-dashboard",
          component: () => import("@/view/pages/central-dashboard.vue"),
          meta: {
            title: `${conf == null ? "Medhop" : conf.clinic_name} | Central Dashboard`,
            // access_right: '1001'
          },
        },

        // promo & coupon efectivity dashboard
        {
          path: "/efectivity-dashboard",
          name: "efectivity-dashboard",
          component: () => import("@/view/pages/promo-coupon-efective-dashboard.vue"),
          meta: {
            title: `${conf == null ? "Medhop" : conf.clinic_name} | Dashboard Efektivitas Promo & Kupon`,
            // access_right: '1001'
          },
        },

        // polyclinic
        {
          path: "/polyclinic",
          redirect: "/polyclinic/list",
          component: () => import("@/view/pages/polyclinics/Polyclinics.vue"),
          children: [
            {
              path: 'list',
              name: 'polyclinic-list',
              component: () => import('@/view/pages/polyclinics/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Poliklinik`,
                access_right: '1002'
              },
            },
            {
              path: 'add',
              name: 'polyclinic-add',
              component: () => import('@/view/pages/polyclinics/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Poliklinik`,
                access_right: '1002'
              },
            },
            {
              path: 'edit/:id',
              name: 'polyclinic-edit',
              component: () => import('@/view/pages/polyclinics/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Poliklinik`,
                access_right: '1002'
              },
            },
            {
              path: 'detail/:id',
              name: 'polyclinic-detail',
              component: () => import('@/view/pages/polyclinics/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Poliklinik`,
                access_right: '1002'
              },
            }
          ]
        },

        // doctor my appointment
        {
          path: "my-appointment",
          name: "my-appointment",
          component: () => import("@/view/pages/doctors/MyAppointment.vue"),
          meta: {
            title: `${conf == null ? "Medhop" : conf.clinic_name} | Perjanjian Saya`,
            access_right: '1003'
          },
        },

        // doctor
        {
          path: "/doctor",
          redirect: "/doctor/list",
          component: () => import("@/view/pages/doctors/Doctors.vue"),
          children: [
            {
              path: "list",
              name: "doctor-list",
              component: () => import("@/view/pages/doctors/List.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Dokter`,
                access_right: '2001'
              },
            },
            {
              path: "add",
              name: "doctor-add",
              component: () => import("@/view/pages/doctors/Add.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Dokter`,
                access_right: '2002'
              },
            },
            {
              path: 'edit/:id',
              name: 'doctor-edit',
              component: () => import('@/view/pages/doctors/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Dokter`,
                access_right: '2002'
              },
            },
            {
              path: 'detail/:id',
              name: 'doctor-detail',
              component: () => import('@/view/pages/doctors/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Dokter`,
                access_right: '2001'
              },
            },
            {
              path: 'holiday/:id',
              name: 'doctor-holiday',
              component: () => import('@/view/pages/doctors/Holiday.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Dokter`,
                access_right: '2001'
              },
            },
            {
              path: 'schedule/:id',
              name: 'doctor-schedule',
              component: () => import('@/view/pages/doctors/Schedule.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Dokter`,
                access_right: '2001'
              },
            },
            {
              path: 'doctor-appointment/:id',
              name: 'doctor-appointment',
              component: () => import('@/view/pages/doctors/Appointment.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Dokter`,
                access_right: '2001'
              },
            },
            {
              path: 'doctor-commission/:id',
              name: 'doctor-commission',
              component: () => import('@/view/pages/doctors/AddDoctorCommision.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Dokter`,
                access_right: '2001'
              },
            },
          ],
        },

        // doctor treatment history
        {
          path: "/doctor/treatments",
          name: "doctor-treatments",
          component: () => import("@/view/pages/doctors/Treatments.vue"),
          meta: {
            title: `${conf == null ? "Medhop" : conf.clinic_name} | Riwayat Pengobatan`,
            access_right: '2003'
          },
        },

        // patient
        {
          path: "/patient",
          redirect: "/patient/list",
          component: () => import("@/view/pages/patients/Patients.vue"),
          children: [
            {
              path: "list",
              name: "patient-list",
              component: () => import("@/view/pages/patients/List.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pasien`,
                access_right: '3001'
              },
            },
            {
              path: "add",
              name: "patient-add",
              component: () => import("@/view/pages/patients/Add.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pasien`,
                access_right: '3002'
              },
            },
            {
              path: 'edit/:id',
              name: 'patient-edit',
              component: () => import('@/view/pages/patients/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pasien`,
                access_right: '3002'
              },
            },
            {
              path: 'detail/:id',
              name: 'patient-detail',
              component: () => import('@/view/pages/patients/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pasien`,
                access_right: '3001'
              },
            },
            {
              path: 'package-paid/:id',
              name: 'package-paid',
              component: () => import('@/view/pages/patients/PackagePaidPatient.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pasien`,
                access_right: '3001'
              },
            },
            {
              path: 'history/:id',
              name: 'patient-history',
              component: () => import('@/view/pages/patients/History.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pasien`,
                access_right: '3001'
              },
            },
            {
              path: 'payment/:id',
              name: 'patient-payment',
              component: () => import('@/view/pages/patients/PaymentHistory.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pasien`,
                access_right: '3001'
              },
            },
            {
              path: "payments",
              name: "patient-payments",
              component: () => import("@/view/pages/patients/Payments.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pasien`,
                access_right: '3001'
              },
            },
            {
              path: "cases",
              name: "patient-cases",
              component: () => import("@/view/pages/patients/CaseManager.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pasien`,
                access_right: '3005'
              },
            },
            {
              path: "cases-print",
              name: "patient-cases-print",
              component: () => import("@/view/pages/patients/PrintMedicalRecord.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pasien`,
                access_right: '3005'
              },
            },
            {
              path: "themplate-print",
              name: "themplate-print",
              component: () => import("@/view/pages/patients/ThemplateMedicalRecord.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Blanko Rekam Medis`,
                access_right: '3005'
              },
            },
            {
              path: "themplate-print-patient/:id",
              name: "themplate-print-patient/:id",
              component: () => import("@/view/pages/patients/ThemplateMedicalRecordPatient.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Blanko Rekam Medis`,
                access_right: '3005'
              },
            },
            {
              path: "documents",
              name: "patient-documents",
              component: () => import("@/view/pages/patients/Documents.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pasien`,
                access_right: '3007'
              },
            },
            {
              path: "drawing/:id",
              name: "patient-drawing",
              component: () => import("@/view/pages/patients/drawingPad.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pasien`,
                access_right: '3007'
              },
            },
            {
              path: 'import',
              name: 'patient-import',
              component: () => import('@/view/pages/patients/Import.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pasien`,
                access_right: '3002'
              },
            },
            {
              path: "distribution-map",
              name: "patient-distribution-map",
              component: () => import("@/view/pages/patients/DistributionMap.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Sebaran Pasien`,
                access_right: '3001'
              },
            },
          ],
        },

        // schedule
        {
          path: "/schedule",
          redirect: '/schedule/list',
          component: () => import("@/view/pages/schedules/Schedules.vue"),
          children: [
            {
              path: "list",
              name: "schedule-list",
              component: () => import("@/view/pages/schedules/List.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Jadwal`,
                access_rigt: '4001'
              },
            },
            {
              path: "holidays",
              name: "schedule-holidays",
              component: () => import("@/view/pages/schedules/Holidays.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Hari Libur`,
                access_rigt: '4001'
              },
            },
          ],
        },

        // Orders
        {
          path: "/order",
          redirect: '/order/list',
          component: () => import("@/view/pages/orders/Orders.vue"),
          children: [
            {
              path: "list",
              name: "schedule-list",
              component: () => import("@/view/pages/orders/List.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Daftar Pesanan`,
                // access_rigt: '4001'
              },
            },
            {
              path: 'detail/:id',
              name: 'patient-detail',
              component: () => import('@/view/pages/orders/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Daftar Pesanan`,
                // access_right: '3001'
              },
            },
          ],
        },

        // appointment
        {
          path: "/appointment",
          name: "appointment",
          redirect: '/appointment/list',
          component: () => import("@/view/pages/appointments/Appointments.vue"),
          children: [
            {
              path: "list/:id",
              name: "appointment-list",
              component: () => import("@/view/pages/appointments/List.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Perjanjian`,
                access_rigt: '5001'
              },
            },
            {
              path: "add",
              name: "appointment-add",
              component: () => import("@/view/pages/appointments/Add.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Perjanjian`,
                access_rigt: '5002'
              },
            },
            {
              path: "edit/:id",
              name: "appointment-edit",
              component: () => import("@/view/pages/appointments/Edit.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Perjanjian`,
                access_rigt: '5002'
              },
            },
            {
              path: "detail/:id",
              name: "appointment-detail",
              component: () => import("@/view/pages/appointments/Detail.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Perjanjian`,
                access_rigt: '5001'
              },
            },
            {
              path: "todays",
              name: "appointment-todays",
              component: () => import("@/view/pages/appointments/Today.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Perjanjian`,
                access_rigt: '5001'
              },
            },
            {
              path: "upcoming",
              name: "appointment-upcoming",
              component: () => import("@/view/pages/appointments/Upcoming.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Perjanjian`,
                access_rigt: '5001'
              },
            },
            {
              path: "calendar",
              name: "appointment-calendar",
              component: () => import("@/view/pages/appointments/Calendar.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Perjanjian`,
                access_rigt: '5001'
              },
            },
            {
              path: "room-calendar",
              name: "room-calendar",
              component: () => import("@/view/pages/appointments/RoomCalendar.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Penggunaan Ruangan`,
                access_rigt: '5001'
              },
            },
          ],
        },

        // human resource
        {
          path: '/human-resource',
          redirect: '/human-resource/employee',
          component: () => import('@/view/pages/human-resource/HumanResource.vue'),
          children: [
            {
              path: '/human-resource/employee',
              redirect: '/human-resource/employee/list',
              component: () => import('@/view/pages/human-resource/employee/Employee.vue'),
              children: [
                {
                  path: 'list',
                  name: 'employee-list',
                  component: () => import('@/view/pages/human-resource/employee/List.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Karyawan`
                  },
                },
                {
                  path: 'add',
                  name: 'employee-add',
                  component: () => import('@/view/pages/human-resource/employee/Add.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Karyawan`
                  },
                },
                {
                  path: 'edit/:id',
                  name: 'employee-edit',
                  component: () => import('@/view/pages/human-resource/employee/Edit.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Karyawan`
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'employee-detail',
                  component: () => import('@/view/pages/human-resource/employee/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Karyawan`
                  },
                }
              ]
            },
            {
              path: '/payrolls',
              redirect: '/payrolls/list',
              component: () => import('@/view/pages/payrolls/Payroll.vue'),
              children: [
                {
                  path: 'list',
                  name: 'payroll-list',
                  component: () => import('@/view/pages/payrolls/List.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Penggajian`
                  },
                },
                {
                  path: 'add',
                  name: 'payroll-add',
                  component: () => import('@/view/pages/payrolls/Add.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Penggajian`
                  },
                },
                {
                  path: 'edit/:id',
                  name: 'payroll-edit',
                  component: () => import('@/view/pages/payrolls/Edit.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Penggajian`
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'payroll-detail',
                  component: () => import('@/view/pages/payrolls/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Penggajian`
                  },
                },
                {
                  path: 'print/:id',
                  name: 'payroll-print',
                  component: () => import('@/view/pages/payrolls/Print.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Penggajian`
                  },
                }
              ]
            },
            {
              path: '/human-resource/pharmacist',
              redirect: '/human-resource/pharmacist/list',
              component: () => import('@/view/pages/human-resource/pharmacist/Pharmacist.vue'),
              children: [
                {
                  path: 'list',
                  name: 'pharmacist-list',
                  component: () => import('@/view/pages/human-resource/pharmacist/List.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Apotek`
                  },
                },
                {
                  path: 'add',
                  name: 'pharmacist-add',
                  component: () => import('@/view/pages/human-resource/pharmacist/Add.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Apotek`
                  },
                },
                {
                  path: 'edit/:id',
                  name: 'pharmacist-edit',
                  component: () => import('@/view/pages/human-resource/pharmacist/Edit.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Apotek`
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'pharmacist-detail',
                  component: () => import('@/view/pages/human-resource/pharmacist/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Apotek`
                  },
                }
              ]
            },
            {
              path: '/human-resource/laboratorist',
              redirect: '/human-resource/laboratorist/list',
              component: () => import('@/view/pages/human-resource/laboratorist/Laboratorist.vue'),
              children: [
                {
                  path: 'list',
                  name: 'laboratorist-list',
                  component: () => import('@/view/pages/human-resource/laboratorist/List.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Lab`
                  },
                },
                {
                  path: 'add',
                  name: 'laboratorist-add',
                  component: () => import('@/view/pages/human-resource/laboratorist/Add.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Lab`
                  },
                },
                {
                  path: 'edit/:id',
                  name: 'laboratorist-edit',
                  component: () => import('@/view/pages/human-resource/laboratorist/Edit.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Lab`
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'laboratorist-detail',
                  component: () => import('@/view/pages/human-resource/laboratorist/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Lab`
                  },
                }
              ]
            },
            {
              path: '/human-resource/accountant',
              redirect: '/human-resource/accountant/list',
              component: () => import('@/view/pages/human-resource/accountant/Accountant.vue'),
              children: [
                {
                  path: 'list',
                  name: 'accountant-list',
                  component: () => import('@/view/pages/human-resource/accountant/List.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Akuntan`
                  },
                },
                {
                  path: 'add',
                  name: 'accountant-add',
                  component: () => import('@/view/pages/human-resource/accountant/Add.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Akuntan`
                  },
                },
                {
                  path: 'edit/:id',
                  name: 'accountant-edit',
                  component: () => import('@/view/pages/human-resource/accountant/Edit.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Akuntan`
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'accountant-detail',
                  component: () => import('@/view/pages/human-resource/accountant/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Akuntan`
                  },
                }
              ]
            },
            {
              path: '/human-resource/receptionist',
              redirect: '/human-resource/receptionist/list',
              component: () => import('@/view/pages/human-resource/receptionist/Receptionist.vue'),
              children: [
                {
                  path: 'list',
                  name: 'receptionist-list',
                  component: () => import('@/view/pages/human-resource/receptionist/List.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Resep Obat`
                  },
                },
                {
                  path: 'add',
                  name: 'receptionist-add',
                  component: () => import('@/view/pages/human-resource/receptionist/Add.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Resep Obat`
                  },
                },
                {
                  path: 'edit/:id',
                  name: 'receptionist-edit',
                  component: () => import('@/view/pages/human-resource/receptionist/Edit.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Resep Obat`
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'receptionist-detail',
                  component: () => import('@/view/pages/human-resource/receptionist/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Resep Obat`
                  },
                }
              ]
            }
          ]
        },

        // payment
        {
          path: 'payment',
          redirect: 'payment/list',
          component: () => import('@/view/pages/payments/Payment.vue'),
          children: [
            {
              path: 'list',
              name: 'payment-list',
              component: () => import('@/view/pages/payments/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pembayaran`,
                access_right: '6001'
              },
            },
            {
              path: 'add',
              name: 'payment-add',
              component: () => import('@/view/pages/payments/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pembayaran`,
                access_right: '6002'
              },
            },
            {
              path: 'edit/:id',
              name: 'payment-edit',
              component: () => import('@/view/pages/payments/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pembayaran`,
                access_right: '6002'
              },
            },
            {
              path: 'detail/:id',
              name: 'payment-detail',
              component: () => import('@/view/pages/payments/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pembayaran`,
                access_right: '6001'
              },
            },
            {
              path: 'slip-detail/:id/:slip_id',
              name: 'payment-slip-detail',
              component: () => import('@/view/pages/payments/SlipDetail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pembayaran`,
                access_right: '6001'
              },
            },
          ]
        },

        // payment-category
        // {
        //   path: 'payment-category',
        //   redirect: 'payment-category/list',
        //   component: () => import('@/view/pages/payment-categories/PaymentCategory.vue'),
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'payment-category-list',
        //       component: () => import('@/view/pages/payment-categories/List.vue'),
        //       meta: {
        //         title: `${conf == null ? "Medhop" : conf.clinic_name } | Biaya Layanan`,
        //         access_right: '6003'
        //       },
        //     },
        //     {
        //       path: 'add',
        //       name: 'payment-category-add',
        //       component: () => import('@/view/pages/payment-categories/Add.vue'),
        //       meta: {
        //         title: `${conf == null ? "Medhop" : conf.clinic_name } | Biaya Layanan',              ` 
        //         access_right: '6009'
        //       },
        //     },
        //     {
        //       path: 'edit/:id',
        //       name: 'payment-category-edit',
        //       component: () => import('@/view/pages/payment-categories/Edit.vue'),
        //       meta: {
        //         title: `${conf == null ? "Medhop" : conf.clinic_name } | Biaya Layanan',              ` 
        //         access_right: '6009'
        //       },
        //     },
        //     {
        //       path: 'detail/:id',
        //       name: 'payment-category-detail',
        //       component: () => import('@/view/pages/payment-categories/Detail.vue'),
        //       meta: {
        //         title: `${conf == null ? "Medhop" : conf.clinic_name } | Biaya Layanan`,
        //         access_right: '6003'
        //       },
        //     },
        //     {
        //       path: 'add-doctor-commision/:id',
        //       name: 'payment-category-add-doctor-commision',
        //       component: () => import('@/view/pages/payment-categories/AddDoctorCommision.vue'),
        //       meta: {
        //         title: `${conf == null ? "Medhop" : conf.clinic_name } | Tambah Komisi Dokter`,
        //         access_right: '6003'
        //       },
        //     },
        //   ]
        // },

        {
          path: '/used-package-service',
          redirect: 'used-package-service/list',
          component: () => import('@/view/pages/used-package-service/UsedPackageService.vue'),
          children: [
            {
              path: 'list',
              name: 'used-package-service-list',
              component: () => import('@/view/pages/used-package-service/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pemakaian Paket Layanan`
                // access_right: '6003'
              },
            },
            {
              path: 'add',
              name: 'used-package-service-add',
              component: () => import('@/view/pages/used-package-service/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pemakaian Paket Layanan`
                // access_right: '6009'
              },
            },
            {
              path: 'edit/:id',
              name: 'used-package-service-edit',
              component: () => import('@/view/pages/used-package-service/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pemakaian Paket Layanan`
                // access_right: '6009'
              },
            },
            {
              path: 'detail/:id',
              name: 'used-package-service-detail',
              component: () => import('@/view/pages/used-package-service/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pemakaian Paket Layanan`
                // access_right: '6003'
              },
            },

          ]
        },
        {
          path: '/payment-categories',
          redirect: 'payment-categories/list',
          component: () => import('@/view/pages/payment-categories/PaymentCategory.vue'),
          children: [
            {
              path: 'list',
              name: 'payment-categories-list',
              component: () => import('@/view/pages/payment-categories/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Produk Layanan`,
                access_right: '6003'
              },
            },
            {
              path: 'add',
              name: 'payment-categories-add',
              component: () => import('@/view/pages/payment-categories/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Produk Layanan`,
                access_right: '6009'
              },
            },
            {
              path: 'edit/:id',
              name: 'payment-categories-edit',
              component: () => import('@/view/pages/payment-categories/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Produk Layanan`,
                access_right: '6009'
              },
            },
            {
              path: 'detail/:id',
              name: 'payment-categories-detail',
              component: () => import('@/view/pages/payment-categories/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Produk Layanan`,
                access_right: '6003'
              },
            },
            {
              path: 'import',
              name: 'payment-categories-import',
              component: () => import('@/view/pages/payment-categories/Import.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Produk Layanan`,
                access_right: '6009'
              },
            },
            {
              path: 'add-doctor-commision/:id',
              name: 'payment-categories-add-doctor-commision',
              component: () => import('@/view/pages/payment-categories/AddDoctorCommision.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Tambah Komisi Dokter`,
                access_right: '6003'
              },
            },
          ]
        },

        // medical checkup
        {
          path: '/medical-checkup',
          redirect: '/medical-checkup/list',
          component: () => import('@/view/pages/medical-checkup/MedicalCheckUp.vue'),
          children: [
            {
              path: 'list',
              name: 'medical-checkup-list',
              component: () => import('@/view/pages/medical-checkup/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Medical Check Up`,
                access_right: '1108'
              },
            },
            {
              path: 'add',
              name: 'medical-checkup-add',
              component: () => import('@/view/pages/medical-checkup/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Medical Check Up`,
                access_right: '1109'
              },
            },
            {
              path: 'edit/:id',
              name: 'medical-checkup-edit',
              component: () => import('@/view/pages/medical-checkup/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Medical Check Up`,
                access_right: '1109'
              },
            },
            {
              path: 'detail/:id',
              name: 'medical-checkup-detal',
              component: () => import('@/view/pages/medical-checkup/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Medical Check Up`,
                access_right: '1108'
              },
            },
            {
              path: 'list-by-patient/:id',
              name: 'medical-checkup-lis-by-patient',
              component: () => import('@/view/pages/medical-checkup/ListByPatient.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Medical Check Up`,
                access_right: '6004'
              },
            }
          ]
        },

        // expense
        {
          path: '/expense',
          redirect: '/expense/list',
          component: () => import('@/view/pages/expenses/Expense.vue'),
          children: [
            {
              path: 'list',
              name: 'expense-list',
              component: () => import('@/view/pages/expenses/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pengeluaran`,
                access_right: '6004'
              },
            },
            {
              path: 'add',
              name: 'expense-add',
              component: () => import('@/view/pages/expenses/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pengeluaran`,
                access_right: '6005'
              },
            },
            {
              path: 'edit/:id',
              name: 'expense-edit',
              component: () => import('@/view/pages/expenses/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pengeluaran`,
                access_right: '6005'
              },
            },
            {
              path: 'detail/:id',
              name: 'expense-detal',
              component: () => import('@/view/pages/expenses/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pengeluaran`,
                access_right: '6004'
              },
            }
          ]
        },

        // expense category
        {
          path: '/expense-categories',
          redirect: '/expense-categories/list',
          component: () => import('@/view/pages/expense-categories/ExpenseCategory.vue'),
          children: [
            {
              path: 'list',
              name: 'expense-category-list',
              component: () => import('@/view/pages/expense-categories/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Kategori Pengeluaran`,
                access_right: '6006'
              },
            },
            {
              path: 'detail/:id',
              name: 'expense-category-detail',
              component: () => import('@/view/pages/expense-categories/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Kategori Pengeluaran`,
                access_right: '6006'
              },
            }
          ]
        },

        // journal report
        {
          path: '/journal-reports',
          redirect: '/journal-reports/list',
          component: () => import('@/view/pages/journal-reports/JournalReport.vue'),
          children: [
            {
              path: 'list',
              name: 'journal-report-list',
              component: () => import('@/view/pages/journal-reports/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Buku Jurnal`,
                access_right: '1506'
              },
            },
            {
              path: 'detail/:id',
              name: 'journal-report-detail',
              component: () => import('@/view/pages/journal-reports/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Buku Jurnal`,
                access_right: '1506'
              },
            }
          ]
        },

        // coa
        {
          path: '/coas',
          name: "coas",
          redirect: "/coas/list",
          component: () => import("@/view/pages/masters/coas/Coa.vue"),
          children: [
            {
              path: '/coas/list',
              name: "coas-list",
              component: () => import("@/view/pages/masters/coas/List.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | - COA`,
                access_right: '6008'
              },
            },
            {
              path: 'add',
              name: "coas-add",
              component: () => import("@/view/pages/masters/coas/Add.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | - COA`,
                access_right: '6010'
              },
            },
            {
              path: '/coas/edit/:id',
              name: "coas-edit",
              component: () => import("@/view/pages/masters/coas/Edit.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | - COA`,
                access_right: '6010'
              },
            },
            {
              path: 'detail/:id',
              name: "coas-detail",
              component: () => import("@/view/pages/masters/coas/Detail.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | - COA`,
                access_right: '6008'
              },
            }
          ]
        },

        // prescription
        {
          path: '/prescriptions',
          redirect: '/prescriptions/list',
          component: () => import('@/view/pages/prescriptions/Prescription.vue'),
          children: [
            {
              path: 'list',
              name: 'prescription-list',
              component: () => import('@/view/pages/prescriptions/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Resep Obat`,
                access_right: '8001'
              },
            },
            {
              path: 'add',
              name: 'prescription-add',
              component: () => import('@/view/pages/prescriptions/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Resep Obat`,
                access_right: '8002'
              },
            },
            {
              path: 'edit/:id',
              name: 'prescription-edit',
              component: () => import('@/view/pages/prescriptions/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Resep Obat`,
                access_right: '8002'
              },
            },
            {
              path: 'detail/:id',
              name: 'prescription-detail',
              component: () => import('@/view/pages/prescriptions/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Resep Obat`,
                access_right: '8001'
              },
            },
            {
              path: 'print/:id',
              name: 'prescription-print',
              component: () => import('@/view/pages/prescriptions/Print.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Resep Obat`,
                access_right: '8001'
              },
            },
          ]
        },

        // lab procedure
        {
          path: '/lab-procedure',
          redirect: '/lab-procedure/list',
          component: () => import('@/view/pages/lab-procedures/LabProcedure.vue'),
          children: [
            {
              path: 'list',
              name: 'lab-procedure-list',
              component: () => import('@/view/pages/lab-procedures/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Harga Lab`,
                access_right: '1101'
              },
            },
            {
              path: 'add',
              name: 'lab-procedure-add',
              component: () => import('@/view/pages/lab-procedures/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Harga Lab`,
                access_right: '1102'
              },
            },
            {
              path: 'edit/:id',
              name: 'lab-procedure-edit',
              component: () => import('@/view/pages/lab-procedures/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Harga Lab`,
                access_right: '1102'
              },
            },
            {
              path: 'detail/:id',
              name: 'lab-procedure-detail',
              component: () => import('@/view/pages/lab-procedures/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Harga Lab`,
                access_right: '1101'
              },
            },
            {
              path: 'add-doctor-commision/:id',
              name: 'lab-procedure-add-doctor-commision',
              component: () => import('@/view/pages/lab-procedures/AddDoctorCommision.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Tambah Komisi Dokter`,
                access_right: '1101'
              },
            },
          ]
        },

        // lab procedure2
        {
          path: '/lab-procedure-2',
          redirect: '/lab-procedure-2/list',
          component: () => import('@/view/pages/lab-procedures-2/LabProcedure.vue'),
          children: [
            {
              path: 'list',
              name: 'lab-procedure-list',
              component: () => import('@/view/pages/lab-procedures-2/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Harga Lab`,
                access_right: '1101'
              },
            },
            {
              path: 'add',
              name: 'lab-procedure-add',
              component: () => import('@/view/pages/lab-procedures-2/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Harga Lab`,
                access_right: '1102'
              },
            },
            {
              path: 'edit/:id',
              name: 'lab-procedure-edit',
              component: () => import('@/view/pages/lab-procedures-2/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Harga Lab`,
                access_right: '1102'
              },
            },
            {
              path: 'detail/:id',
              name: 'lab-procedure-detail',
              component: () => import('@/view/pages/lab-procedures-2/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Harga Lab`,
                access_right: '1101'
              },
            },
            {
              path: 'add-doctor-commision/:id',
              name: 'lab-procedure-add-doctor-commision',
              component: () => import('@/view/pages/lab-procedures/AddDoctorCommision.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Tambah Komisi Dokter`,
                access_right: '1101'
              },
            },
            {
              path: 'edit-templates/:templateid',
              name: 'lab-procedure-edit-template',
              component: () => import('@/view/pages/lab-procedures-2/EditTemplate.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Harga Lab`,
                access_right: '1101'
              },
            },
          ]
        },

        // lab report
        {
          path: '/lab-report',
          redirect: '/lab-report/list',
          component: () => import('@/view/pages/lab-reports/LabReport.vue'),
          children: [
            {
              path: 'list',
              name: 'lab-report-list',
              component: () => import('@/view/pages/lab-reports/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1103'
              },
            },
            {
              path: 'add',
              name: 'lab-report-add',
              component: () => import('@/view/pages/lab-reports/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1104'
              },
            },
            {
              path: 'edit/:id',
              name: 'lab-report-edit',
              component: () => import('@/view/pages/lab-reports/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1104'
              },
            },
            {
              path: 'detail/:id',
              name: 'lab-report-detail',
              component: () => import('@/view/pages/lab-reports/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1103'
              },
            },
            {
              path: 'attachment/:id',
              name: 'lab-report-attachment',
              component: () => import('@/view/pages/lab-reports/Attachment.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1103'
              },
            }
          ]
        },
        // lab report
        {
          path: '/lab-report-2',
          redirect: '/lab-report-2/list',
          component: () => import('@/view/pages/lab-reports-2/LabReport.vue'),
          children: [
            {
              path: 'list',
              name: 'lab-report-2-list',
              component: () => import('@/view/pages/lab-reports-2/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1103'
              },
            },
            {
              path: 'add',
              name: 'lab-report-2-add',
              component: () => import('@/view/pages/lab-reports-2/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1104'
              },
            },
            {
              path: 'edit/:id',
              name: 'lab-report-2-edit',
              component: () => import('@/view/pages/lab-reports-2/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1104'
              },
            },
            {
              path: 'detail/:id',
              name: 'lab-report-2-detail',
              component: () => import('@/view/pages/lab-reports-2/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1103'
              },
            },
            {
              path: 'attachment/:id',
              name: 'lab-report-2-attachment',
              component: () => import('@/view/pages/lab-reports-2/Attachment.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1103'
              },
            }
          ]
        },

        // crm
        {
          path: '/crm',
          redirect: '/crm/list',
          component: () => import('@/view/pages/crm/CRM.vue'),
          children: [
            {
              path: 'list',
              name: 'crm-list',
              component: () => import('@/view/pages/crm/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Customer Relationship Management (CRM)`,
                access_right: '3001'
              },
            },
            {
              path: 'list-recently',
              name: 'crm-list-recently',
              component: () => import('@/view/pages/crm/RecentlyVisitPatient.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Customer Relationship Management (CRM)`,
                access_right: '3001'
              },
            },
            {
              path: 'list-deposit',
              name: 'crm-list-deposit',
              component: () => import('@/view/pages/crm/DepositReminder.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Customer Relationship Management (CRM)`,
                access_right: '3001'
              },
            },
            {
              path: 'broadcast-campaigns',
              name: 'crm-list-campaign',
              component: () => import('@/view/pages/crm/Broadcast.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Customer Relationship Management (CRM)`,
                access_right: '3001'
              },
            },
            {
              path: 'patient-spending-money',
              name: 'crm-list-patient-spending-money',
              component: () => import('@/view/pages/crm/SpentMoney.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Customer Relationship Management (CRM)`,
                access_right: '3001'
              },
            },
          ]
        },

        // medicine
        {
          path: '/medicine',
          redirect: '/medicine/list',
          component: () => import('@/view/pages/medicine/Medicine.vue'),
          children: [
            {
              path: 'list',
              name: 'medicine-list',
              component: () => import('@/view/pages/medicine/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Obat`,
                access_right: '9001'
              },
            },
            {
              path: 'add',
              name: 'medicine-add',
              component: () => import('@/view/pages/medicine/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Obat`,
                access_right: '9002'
              },
            },
            {
              path: 'edit/:id',
              name: 'medicine-edit',
              component: () => import('@/view/pages/medicine/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Obat`,
                access_right: '9002'
              },
            },
            {
              path: 'detail/:id',
              name: 'medicine-detail',
              component: () => import('@/view/pages/medicine/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Obat`,
                access_right: '9001'
              },
            },
            {
              path: 'import',
              name: 'medicine-import',
              component: () => import('@/view/pages/medicine/Import.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Obat`,
                access_right: '9002'
              },
            },
            {
              path: 'category',
              name: 'medicine-category',
              component: () => import('@/view/pages/medicine/Category.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Kategori Obat`,
                access_right: '9003'
              },
            },
            {
              path: 'maintain-stock',
              name: 'medicine-stock',
              component: () => import('@/view/pages/medicine/MaintainStock.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Obat`,
                access_right: '9004'
              },
            },
            {
              path: 'maintain-stock-add',
              name: 'medicine-stock-add',
              component: () => import('@/view/pages/medicine/MaintainStockAdd.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Obat`,
                access_right: '9005'
              },
            },
            // {
            //   path: 'maintain-stock-edit/:id',
            //   name: 'medicine-stock-edit',
            //   component: () => import('@/view/pages/medicine/MaintainStockEdit.vue'),
            //   meta: {
            //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Stok Obat`,
            //     access_right: '9005'
            //   },
            // },
            {
              path: 'maintain-stock-detail/:id',
              name: 'medicine-stock-detail',
              component: () => import('@/view/pages/medicine/MaintainStockDetail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Obat`,
                access_right: '9004'
              },
            },
            {
              path: 'maintain-stock-update/:id',
              name: 'medicine-stock-update',
              component: () => import('@/view/pages/medicine/MaintainStockEdit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Obat`,
                access_right: '9005'
              },
            },
            {
              path: 'batch-stock/:id',
              name: 'batch-stock',
              component: () => import('@/view/pages/medicine/MedicineBatchStock.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Obat`,
                access_right: '9004'
              },
            },
            {
              path: 'batch-stock-per-batch/:id',
              name: 'batch-stock-per-batch',
              component: () => import('@/view/pages/medicine/MedicineBatchStockPerBatch.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Obat`,
                access_right: '9004'
              },
            },
            {
              path: 'medicine-order',
              name: 'medicine-order',
              component: () => import('@/view/pages/medicine/Instruction.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Obat`,
                access_right: '9004'
              },
            },
            {
              path: 'expiration-warning',
              name: 'expiration-warning',
              component: () => import('@/view/pages/medicine/ExpirationWarning.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Obat`,
                access_right: '9004'
              },
            },
            {
              path: 'purchase-order',
              name: 'purchase-order',
              component: () => import('@/view/pages/medicine/PurchaseOrder.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Obat`,
                access_right: '9004'
              },
            },
            {
              path: 'purchase-orders/add',
              name: 'purchase-order-add',
              component: () => import('@/view/pages/medicine/PurchaseOrderAdd.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Obat`,
                access_right: '9004'
              },
            },
            {
              path: 'purchase-orders/edit/:id',
              name: 'purchase-order-edit',
              component: () => import('@/view/pages/medicine/PurchaseOrderEdit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Obat`,
                access_right: '9004'
              },
            },
            {
              path: 'purchase-orders/detail/:id',
              name: 'purchase-order-detail',
              component: () => import('@/view/pages/medicine/PurchaseOrderDetail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Obat`,
                access_right: '9004'
              },
            },
            {
              path: 'vendors/list',
              name: 'vendors-list',
              component: () => import('@/view/pages/masters/vendors/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Supplier`,
                access_right: '9006'
              },
            },
            {
              path: 'vendors/add',
              name: 'vendors-add',
              component: () => import('@/view/pages/masters/vendors/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Supplier`,
                access_right: '9007'
              },
            },
            {
              path: 'vendors/edit/:id',
              name: 'vendors-edit',
              component: () => import('@/view/pages/masters/vendors/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Supplier`,
                access_right: '9007'
              },
            },
            {
              path: 'vendors/detail/:id',
              name: 'vendors-detail',
              component: () => import('@/view/pages/masters/vendors/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Supplier`,
                access_right: '9006'
              },
            },
          ]
        },

        // po apotek
        {
          path: '/purchase-orders-pharmacy',
          redirect: '/purchase-orders-pharmacy/list',
          component: () => import('@/view/pages/medicine/PurchaseOrder.vue'),
          children: [
            {
              path: 'list',
              name: 'purchase-orders-pharmacy-list',
              component: () => import('@/view/pages/medicine/PurchaseOrderList.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1406'
              },
            },
            {
              path: 'add',
              name: 'purchase-orders-pharmacy-add',
              component: () => import('@/view/pages/medicine/PurchaseOrderAdd.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1407'
              },
            },
            {
              path: 'edit/:id',
              name: 'purchase-orders-pharmacy-edit',
              component: () => import('@/view/pages/medicine/PurchaseOrderEdit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1407'
              },
            },
            {
              path: 'detail/:id',
              name: 'purchase-orders-pharmacy-detail',
              component: () => import('@/view/pages/medicine/PurchaseOrderDetail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Lab`,
                access_right: '1406'
              },
            }
          ]
        },

        // beds
        {
          path: '/beds',
          redirect: '/beds/list',
          component: () => import('@/view/pages/beds/Beds.vue'),
          children: [
            {
              path: 'list',
              name: 'bed-list',
              component: () => import('@/view/pages/beds/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Bangsal`,
                access_right: '1301'
              },
            },
            {
              path: 'add',
              name: 'bed-add',
              component: () => import('@/view/pages/beds/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Bangsal`,
                access_right: '1302'
              },
            },
            {
              path: 'edit/:id',
              name: 'bed-edit',
              component: () => import('@/view/pages/beds/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Bangsal`,
                access_right: '1302'
              },
            },
            {
              path: 'detail/:id',
              name: 'bed-detail',
              component: () => import('@/view/pages/beds/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Bangsal`,
                access_right: '1301'
              },
            },
            {
              path: 'category',
              name: 'bed-category',
              component: () => import('@/view/pages/beds/Category.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Kategori Bangsal`,
                access_right: '1304'
              },
            },
            {
              path: 'allotment',
              name: 'bed-allotment',
              component: () => import('@/view/pages/beds/Allotment.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pemakaian Bangsal`,
                access_right: '1303'
              },
            },
            {
              path: 'add-allotment',
              name: 'bed-add-allotment',
              component: () => import('@/view/pages/beds/AddAllotment.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pemakaian Bangsal`,
                access_right: '1305'
              },
            },
            {
              path: 'edit-allotment/:id',
              name: 'bed-edit-allotment',
              component: () => import('@/view/pages/beds/EditAllotment.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pemakaian Bangsal`,
                access_right: '1305'
              },
            },
            {
              path: 'allotment-history',
              name: 'bed-allotment-history',
              component: () => import('@/view/pages/beds/AllotmentHistory.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pemakaian Bangsal`,
                access_right: '1303'
              },
            }
          ]
        },

        // inventory
        {
          path: '/inventory',
          redirect: '/inventory/list',
          component: () => import('@/view/pages/inventory/Inventory.vue'),
          children: [
            {
              path: 'list',
              name: 'inventory-list',
              component: () => import('@/view/pages/inventory/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Inventori`,
                access_right: '9003'
              },
            },
            {
              path: 'add',
              name: 'inventory-add',
              component: () => import('@/view/pages/inventory/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Inventori`,
                access_right: '9003'
              },
            },
            {
              path: 'edit/:id',
              name: 'inventory-edit',
              component: () => import('@/view/pages/inventory/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Inventori`,
                access_right: '9003'
              },
            },
            {
              path: 'detail/:id',
              name: 'inventory-detail',
              component: () => import('@/view/pages/inventory/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Inventori`,
                access_right: '9003'
              },
            },
            {
              path: 'category',
              name: 'inventory-category',
              component: () => import('@/view/pages/inventory/Category.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Kategori Inventori`,
                access_right: '9003'
              },
            },
            {
              path: 'maintain-stock',
              name: 'inventory-stock',
              component: () => import('@/view/pages/inventory/MaintainStock.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Kartu Stok`,
                access_right: '9004'
              },
            },
            {
              path: 'maintain-stock-add',
              name: 'inventory-stock-add',
              component: () => import('@/view/pages/inventory/MaintainStockAdd.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Kartu Stok`,
                access_right: '9005'
              },
            },
            {
              path: 'maintain-stock-edit/:id',
              name: 'inventory-stock-edit',
              component: () => import('@/view/pages/inventory/MaintainStockEdit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Kartu Stok`,
                access_right: '9005'
              },
            },
            {
              path: 'maintain-stock-detail/:id',
              name: 'inventory-stock-detail',
              component: () => import('@/view/pages/inventory/MaintainStockDetail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Kartu Stok`,
                access_right: '9005'
              },
            },
            {
              path: 'stock-opname',
              name: 'inventory-stock-opname-list',
              component: () => import('@/view/pages/inventory/InventoryStockOpname.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9005'
              },
            },
            {
              path: 'stock-opname-add',
              name: 'inventory-stock-opname-add',
              component: () => import('@/view/pages/inventory/InventoryStockOpnameAdd.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9005'
              },
            },
            {
              path: 'stock-opname-edit/:id',
              name: 'inventory-stock-opname-edit',
              component: () => import('@/view/pages/inventory/InventoryStockOpnameEdit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9005'
              },
            },
            {
              path: 'stock-opname-detail/:id',
              name: 'inventory-stock-opname-detail',
              component: () => import('@/view/pages/inventory/InventoryStockOpnameDetail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9005'
              },
            },
            {
              path: 'stock-opname-print/:id',
              name: 'inventory-stock-opname-print',
              component: () => import('@/view/pages/inventory/InventoryStockOpnamePrint.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9005'
              },
            },
            {
              path: 'stock-opname-report',
              name: 'inventory-stock-opname-report',
              component: () => import('@/view/pages/inventory/InventoryStockOpnameReport.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9005'
              },
            },
            {
              path: 'stock-opname-report/detail/:id',
              name: 'inventory-stock-opname-report-detail',
              component: () => import('@/view/pages/inventory/InventoryStockOpnameReportDetail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9005'
              },
            },
            {
              path: 'stock-opname-report/print/:id',
              name: 'inventory-stock-opname-report-print',
              component: () => import('@/view/pages/inventory/InventoryStockOpnameReportPrint.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9005'
              },
            },
            {
              path: 'purchase-order',
              name: 'inventory-purchase-order',
              component: () => import('@/view/pages/inventory/PurchaseOrderList.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9005'
              },
            },
            {
              path: 'purchase-order/add',
              name: 'inventory-purchase-order-add',
              component: () => import('@/view/pages/inventory/PurchaseOrderAdd.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9005'
              },
            },
            {
              path: 'purchase-order/edit/:id',
              name: 'inventory-purchase-order-edit',
              component: () => import('@/view/pages/inventory/PurchaseOrderEdit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9005'
              },
            },
            {
              path: 'purchase-order/detail/:id',
              name: 'inventory-purchase-order-detail',
              component: () => import('@/view/pages/inventory/PurchaseOrderDetail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9005'
              },
            },
            {
              path: 'import',
              name: 'inventory-import',
              component: () => import('@/view/pages/inventory/Import.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Inventori`,
                access_right: '9003'
              },
            },
          ]
        },
        // crm
        {
          path: "/satu-sehat",
          redirect: "/satu-sehat/posting",
          component: () => import("@/view/pages/satu-sehat/SatuSehat.vue"),
          children: [
            {
              path: "posting",
              name: "satu-sehat-posting",
              component: () => import("@/view/pages/satu-sehat/Posting.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Satu Sehat`,
                access_right: "10002",
              },
            },
            {
              path: "dashboard",
              name: "satu-sehat-dashboard",
              component: () => import("@/view/pages/satu-sehat/Dashboard.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Satu Sehat`,
                access_right: "10001",
              },
            },
            {
              path: "patients/detail/:id/:ss_patient_id",
              name: "satu-sehat-patients-detail",
              component: () => import("@/view/pages/satu-sehat/DetailReturnMedicalRecord.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Satu Sehat`,
                access_right: "10001",
              },
            },
            {
              path: "access-token",
              name: "satu-sehat-access-token",
              component: () => import("@/view/pages/satu-sehat/AccessToken.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Satu Sehat`,
                access_right: "10003",
              },
            },
          ]
        },
        // donors        
        {
          path: '/donors',
          redirect: '/donors/list',
          component: () => import('@/view/pages/donors/Donors.vue'),
          children: [
            {
              path: 'list',
              name: 'donor-list',
              component: () => import('@/view/pages/donors/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Donor`,
                access_right: '2201'
              },
            },
            {
              path: 'add',
              name: 'donor-add',
              component: () => import('@/view/pages/donors/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Donor`,
                access_right: '2202'
              },
            },
            {
              path: 'edit/:id',
              name: 'donor-edit',
              component: () => import('@/view/pages/donors/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Donor`,
                access_right: '2202'
              },
            },
            {
              path: 'detail/:id',
              name: 'donor-detail',
              component: () => import('@/view/pages/donors/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Donor`,
                access_right: '2201'
              },
            }
          ]
        },

        // stock-opname        
        {
          path: '/stock-opnames',
          redirect: '/stock-opnames/list',
          component: () => import('@/view/pages/stock-opnames/StockOpname.vue'),
          children: [
            {
              path: 'list',
              name: 'stock-opname-list',
              component: () => import('@/view/pages/stock-opnames/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9009'
              },
            },
            {
              path: 'add',
              name: 'stock-opname-add',
              component: () => import('@/view/pages/stock-opnames/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9010'
              },
            },
            {
              path: 'edit/:id',
              name: 'stock-opname-edit',
              component: () => import('@/view/pages/stock-opnames/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9010'
              },
            },
            {
              path: 'detail/:id',
              name: 'stock-opname-detail',
              component: () => import('@/view/pages/stock-opnames/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9009'
              },
            },
            {
              path: 'print/:id',
              name: 'stock-opname-print',
              component: () => import('@/view/pages/stock-opnames/Print.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9009'
              },
            }
          ]
        },

        // medical actions
        {
          path: '/medical-actions',
          redirect: '/medical-actions/list',
          component: () => import('@/view/pages/medical-actions/MedicalAction.vue'),
          children: [
            {
              path: 'list',
              name: 'medical-actions-list',
              component: () => import('@/view/pages/medical-actions/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Tindakan Medis`,
                access_right: '1201'
              },
            },
            {
              path: 'add',
              name: 'medical-actions-add',
              component: () => import('@/view/pages/medical-actions/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Tindakan Medis`,
                access_right: '1202'
              },
            },
            {
              path: 'edit/:id',
              name: 'medical-actions-edit',
              component: () => import('@/view/pages/medical-actions/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Tindakan Medis`,
                access_right: '1202'
              },
            },
            {
              path: 'detail/:id',
              name: 'medical-actions-detail',
              component: () => import('@/view/pages/medical-actions/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Tindakan Medis`,
                access_right: '1201'
              },
            }
          ]
        },

        // medicine-package
        {
          path: '/medicine-packages',
          redirect: '/medicine-packages/list',
          component: () => import('@/view/pages/medicine-packages/MedicinePackage.vue'),
          children: [
            {
              path: 'list',
              name: 'medicine-packages-list',
              component: () => import('@/view/pages/medicine-packages/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Shortcut Obat dan Alkes`,
                access_right: '1201'
              },
            },
            {
              path: 'add',
              name: 'medicine-packages-add',
              component: () => import('@/view/pages/medicine-packages/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Shortcut Obat dan Alkes`,
                access_right: '1202'
              },
            },
            {
              path: 'edit/:id',
              name: 'medicine-packages-edit',
              component: () => import('@/view/pages/medicine-packages/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Shortcut Obat dan Alkes`,
                access_right: '1202'
              },
            },
            {
              path: 'detail/:id',
              name: 'medicine-packages-detail',
              component: () => import('@/view/pages/medicine-packages/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Shortcut Obat dan Alkes`,
                access_right: '1201'
              },
            }
          ]
        },
        // action-repoorts
        {
          path: '/action-reports',
          redirect: '/action-reports/list',
          component: () => import('@/view/pages/action-reports/ActionReport.vue'),
          children: [
            {
              path: 'list',
              name: 'action-reports-list',
              component: () => import('@/view/pages/action-reports/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} |Laporan Tindakan Perbulan`,
                // access_right: '1201'
              },
            },
            // {
            //   path: 'add',
            //   name: 'action-reports-add',
            //   component: () => import('@/view/pages/action-reports/Add.vue'),
            //   meta: {
            //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Shortcut Obat dan Alkes`,
            //     access_right: '1202'
            //   },
            // },
            // {
            //   path: 'edit/:id',
            //   name: 'action-reports-edit',
            //   component: () => import('@/view/pages/action-reports/Edit.vue'),
            //   meta: {
            //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Shortcut Obat dan Alkes`,
            //     access_right: '1202'
            //   },
            // },
            // {
            //   path: 'detail/:id',
            //   name: 'action-reports-detail',
            //   component: () => import('@/view/pages/action-reports/Detail.vue'),
            //   meta: {
            //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Shortcut Obat dan Alkes`,
            //     access_right: '1201'
            //   },
            // }
          ]
        },

        // action types
        {
          path: '/masters/action-types',
          name: "action-types",
          redirect: "/masters/action-types/list",
          component: () => import("@/view/pages/masters/action-types/ActionType.vue"),
          meta: {
            title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Jenis Tindaka`
          },
          children: [
            {
              path: '/masters/action-types/list',
              name: "action-types-list",
              component: () => import("@/view/pages/masters/action-types/List.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Jenis Tindakan`,
                access_right: '1203'
              },
            },
            {
              path: '/masters/action-types/add',
              name: "action-types-add",
              component: () => import("@/view/pages/masters/action-types/Add.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Jenis Tindakan`,
                access_right: '1204'
              },
            },
            {
              path: '/masters/action-types/edit/:id',
              name: "action-types-edit",
              component: () => import("@/view/pages/masters/action-types/Edit.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Jenis Tindakan`,
                access_right: '1204'
              },
            },
            {
              path: 'detail/:id',
              name: "action-types-detail",
              component: () => import("@/view/pages/masters/action-types/Detail.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Jenis Tindakan`,
                access_right: '1203'
              },
            }
          ]
        },

        // donor-recipient        
        {
          path: '/donor-recipients',
          redirect: '/donor-recipients/list',
          component: () => import('@/view/pages/donor-recipients/DonorRecipient.vue'),
          children: [
            {
              path: 'list',
              name: 'donor-recipients-list',
              component: () => import('@/view/pages/donor-recipients/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Penerima Donor`,
                access_right: '2401',
              },
            },
            {
              path: 'add',
              name: 'donor-recipients-add',
              component: () => import('@/view/pages/donor-recipients/Add.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Penerima Donor`,
                access_right: '2402',
              },
            },
            {
              path: 'edit/:id',
              name: 'donor-recipients-edit',
              component: () => import('@/view/pages/donor-recipients/Edit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Penerima Donor`,
                access_right: '2402',
              },
            },
            {
              path: 'detail/:id',
              name: 'donor-recipients-detail',
              component: () => import('@/view/pages/donor-recipients/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Penerima Donor`,
                access_right: '2401',
              },
            }
          ]
        },

        // blood bank        
        {
          path: '/blood-bank',
          redirect: '/blood-bank/list',
          component: () => import('@/view/pages/blood-banks/BloodBank.vue'),
          children: [
            {
              path: 'list',
              name: 'blood-bank-list',
              component: () => import('@/view/pages/blood-banks/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Bank Darah`,
                access_right: '2501'
              },
            },
          ]
        },

        // report
        {
          path: '/report',
          redirect: '/report',
          component: () => import('@/view/pages/reports/Report.vue'),
          children: [
            {
              path: 'payment-report',
              name: 'payment-report',
              component: () => import('@/view/pages/reports/PaymentReport.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Pembayaran`,
                access_right: '1501'
              },
            },
            {
              path: 'payment-pharmacy-report',
              name: 'payment-pharmacy-report',
              component: () => import('@/view/pages/reports/PharmacyHospitalReport.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Pembayaran Apotik Rumah Sakit`,
                access_right: '1501'
              },
            },
            {
              path: 'doctor-commision',
              name: 'report-doctor-commision',
              component: () => import('@/view/pages/reports/DoctorCommisionReport.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Komisi Dokter`,
                access_right: '1502'
              },
            },
            {
              path: 'employee-commision',
              name: 'report-employee-commision',
              component: () => import('@/view/pages/reports/EmployeeCommisionReport.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Komisi Pegawai`,
                access_right: '1502'
              },
            },
            {
              path: 'expense-report',
              name: 'expense-report',
              component: () => import('@/view/pages/reports/ExpenseReport.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Pengeluaran`,
                access_right: '1503'
              },
            },
            {
              path: 'pharmacy-report',
              name: 'pharmacy-report',
              component: () => import('@/view/pages/reports/PharmacyReport.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Apotek`,
                access_right: '1504'
              },
            },
            {
              path: 'pharmacy-expense-report',
              name: 'pharmacy-expense-report',
              component: () => import('@/view/pages/reports/PharmacyExpenseReport.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Pengeluaran Apotek`,
                access_right: '1505'
              },
            },
            {
              path: 'payroll-report',
              name: 'payroll-report',
              component: () => import('@/view/pages/reports/PayrollReport.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Laporan Penggajian Karyawan`,
                access_right: '1507'
              },
            },
            {
              path: 'payment-chart',
              name: 'payment-chart',
              component: () => import('@/view/pages/reports/PaymentChart.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Grafik Pembayaran`,
                access_right: '1501'
              },
            },
            {
              path: 'expense-chart',
              name: 'expense-chart',
              component: () => import('@/view/pages/reports/ExpenseChart.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Grafik Pengeluaran`,
                access_right: '1503'
              },
            },
            {
              path: 'pharmacy-chart',
              name: 'pharmacy-chart',
              component: () => import('@/view/pages/reports/PharmacyChart.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Grafik Pendapatan Apotek`,
                access_right: '1504'
              },
            },
            {
              path: 'pharmacy-expense-chart',
              name: 'pharmacy-expense-chart',
              component: () => import('@/view/pages/reports/PharmacyExpenseChart.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Grafik Pengeluaran Apotek`,
                access_right: '1504'
              }
            },
          ]
        },

        // stock-opname-report        
        {
          path: '/stock-opname-report',
          redirect: '/stock-opname-report/list',
          component: () => import('@/view/pages/stock-opname-report/StockOpnameReport.vue'),
          children: [
            {
              path: 'list',
              name: 'stock-opname-report-list',
              component: () => import('@/view/pages/stock-opname-report/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9009'
              },
            },
            {
              path: 'detail/:id',
              name: 'stock-opname-report-detail',
              component: () => import('@/view/pages/stock-opname-report/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stok Opname`,
                access_right: '9009'
              },
            },
            {
              path: 'print/:id',
              name: 'stock-opname-report-print',
              component: () => import('@/view/pages/stock-opname-report/Print.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Stock Opname`,
                access_right: '9009'
              },
            }
          ]
        },

        // announcements
        {
          path: '/announcements',
          name: "announcements",
          redirect: "/announcements/list",
          component: () => import("@/view/pages/masters/announcements/Announcement.vue"),
          children: [
            {
              path: '/announcements/list',
              name: "announcements-list",
              component: () => import("@/view/pages/masters/announcements/List.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Pengumuman`,
                access_right: '2301'
              },
            },
            {
              path: '/announcements/add',
              name: "announcements-add",
              component: () => import("@/view/pages/masters/announcements/Add.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Pengumuman`,
                access_right: '2302'
              },
            },
            {
              path: '/announcements/edit/:id',
              name: "announcements-edit",
              component: () => import("@/view/pages/masters/announcements/Edit.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Pengumuman`,
                access_right: '2302'
              },
            },
            {
              path: '/announcements/detail/:id',
              name: "announcements-detail",
              component: () => import("@/view/pages/masters/announcements/Detail.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Pengumuman`,
                access_right: '2301'
              },
            }
          ]
        },

        // purchase order klinik
        {
          path: '/purchase-orders',
          name: "purchase-orders",
          redirect: "/purchase-orders/list",
          component: () => import("@/view/pages/purchase-orders/PurchaseOrder.vue"),
          children: [
            {
              path: '/purchase-orders/list',
              name: "purchase-orders-list",
              component: () => import("@/view/pages/purchase-orders/List.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | PO - Klinik`,
                access_right: '2301'
              },
            },
            {
              path: '/purchase-orders/add',
              name: "purchase-orders-add",
              component: () => import("@/view/pages/purchase-orders/Add.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | PO - Klinik`,
                access_right: '2302'
              },
            },
            {
              path: '/purchase-orders/edit/:id',
              name: "purchase-orders-edit",
              component: () => import("@/view/pages/purchase-orders/Edit.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | PO - Klinik`,
                access_right: '2302'
              },
            },
            {
              path: '/purchase-orders/detail/:id',
              name: "purchase-orders-detail",
              component: () => import("@/view/pages/purchase-orders/Detail.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | PO - Klinik`,
                access_right: '2301'
              },
            }
          ]
        },

        // masters
        {
          path: "/masters",
          name: "masters",
          redirect: "/masters/appointment-statuses/list",
          component: () => import("@/view/pages/masters/Masters.vue"),
          children: [
            {
              path: '/masters/appointment-statuses',
              name: "appointment-statuses",
              redirect: "/masters/appointment-statuses/list",
              component: () => import("@/view/pages/masters/appointment-statuses/AppointmentStatus.vue"),
              children: [
                {
                  path: '/masters/appointment-statuses/list',
                  name: "appointment-statuses-list",
                  component: () => import("@/view/pages/masters/appointment-statuses/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Status Perjanjian`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/appointment-statuses/add',
                  name: "appointment-statuses-add",
                  component: () => import("@/view/pages/masters/appointment-statuses/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Status Perjanjian`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/appointment-statuses/edit/:id',
                  name: "appointment-statuses-edit",
                  component: () => import("@/view/pages/masters/appointment-statuses/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Status Perjanjian`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'appointment-statuses-detail',
                  component: () => import('@/view/pages/masters/appointment-statuses/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Status Perjanjian`,
                    access_right: '1801'
                  },
                }
              ]
            },
            {
              path: '/masters/lab-categories',
              name: "lab-categories",
              redirect: "/masters/lab-categories/list",
              component: () => import("@/view/pages/masters/lab-categories/LabCategory.vue"),
              children: [
                {
                  path: '/masters/lab-categories/list',
                  name: "lab-categories-list",
                  component: () => import("@/view/pages/masters/lab-categories/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Kategori Lab`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/lab-categories/add',
                  name: "lab-categories-add",
                  component: () => import("@/view/pages/masters/lab-categories/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Kategori Lab`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/lab-categories/edit/:id',
                  name: "lab-categories-edit",
                  component: () => import("@/view/pages/masters/lab-categories/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Kategori Lab`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'lab-categories-detail',
                  component: () => import('@/view/pages/masters/lab-categories/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Kategori Lab`,
                    access_right: '1801'
                  },
                }
              ]
            },
            {
              path: '/masters/uoms',
              name: "uoms",
              redirect: "/masters/uoms/list",
              component: () => import("@/view/pages/masters/uoms/Uom.vue"),
              children: [
                {
                  path: '/masters/uoms/list',
                  name: "uoms-list",
                  component: () => import("@/view/pages/masters/uoms/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Jenis Lab`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/uoms/add',
                  name: "uoms-add",
                  component: () => import("@/view/pages/masters/uoms/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Jenis Lab`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/uoms/edit/:id',
                  name: "uoms-edit",
                  component: () => import("@/view/pages/masters/uoms/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Jenis Lab`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'uoms-detail',
                  component: () => import('@/view/pages/masters/uoms/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Jenis Lab`,
                    access_right: '1801'
                  },
                }
              ]
            },
            {
              path: '/masters/banks',
              name: "banks",
              redirect: "/masters/banks/list",
              component: () => import("@/view/pages/masters/banks/Bank.vue"),
              children: [
                {
                  path: '/masters/banks/list',
                  name: "banks-list",
                  component: () => import("@/view/pages/masters/banks/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Jenis Bank`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/banks/add',
                  name: "banks-add",
                  component: () => import("@/view/pages/masters/banks/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Jenis Bank`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/banks/edit/:id',
                  name: "banks-edit",
                  component: () => import("@/view/pages/masters/banks/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Jenis Bank`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'banks-detail',
                  component: () => import('@/view/pages/masters/banks/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Jenis Bank`,
                    access_right: '1801'
                  },
                }
              ]
            },
            {
              path: '/masters/coupons',
              name: "coupons",
              redirect: "/masters/coupons/list",
              component: () => import("@/view/pages/masters/coupons/Coupon.vue"),
              children: [
                {
                  path: '/masters/coupons/list',
                  name: "coupons-list",
                  component: () => import("@/view/pages/masters/coupons/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Kupon`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/coupons/add',
                  name: "coupons-add",
                  component: () => import("@/view/pages/masters/coupons/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Kupon`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/coupons/edit/:id',
                  name: "coupons-edit",
                  component: () => import("@/view/pages/masters/coupons/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Kupon`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'coupons-detail',
                  component: () => import('@/view/pages/masters/coupons/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Kupon`,
                    access_right: '1801'
                  },
                }
              ]
            },
            {
              path: '/masters/promos',
              name: "promos",
              redirect: "/masters/promos/list",
              component: () => import("@/view/pages/masters/promos/Promos.vue"),
              children: [
                {
                  path: '/masters/promos/list',
                  name: "promos-list",
                  component: () => import("@/view/pages/masters/promos/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Promo`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/promos/add',
                  name: "promos-add",
                  component: () => import("@/view/pages/masters/promos/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Promo`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/promos/edit/:id',
                  name: "promos-edit",
                  component: () => import("@/view/pages/masters/promos/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Promo`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'promos-detail',
                  component: () => import('@/view/pages/masters/promos/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Promo`,
                    access_right: '1801'
                  },
                }
              ]
            },
            {
              path: '/masters/buildings',
              name: "buildings",
              redirect: "/masters/buildings/list",
              component: () => import("@/view/pages/masters/buildings/Building.vue"),
              children: [
                {
                  path: '/masters/buildings/list',
                  name: "buildings-list",
                  component: () => import("@/view/pages/masters/buildings/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Gedung`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/buildings/add',
                  name: "buildings-add",
                  component: () => import("@/view/pages/masters/buildings/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Gedung`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/buildings/edit/:id',
                  name: "buildings-edit",
                  component: () => import("@/view/pages/masters/buildings/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Gedung`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'buildings-detail',
                  component: () => import('@/view/pages/masters/buildings/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Gedung`,
                    access_right: '1801'
                  },
                }
              ]
            },
            {
              path: '/masters/rooms',
              name: "rooms",
              redirect: "/masters/rooms/list",
              component: () => import("@/view/pages/masters/rooms/Room.vue"),
              children: [
                {
                  path: '/masters/rooms/list',
                  name: "rooms-list",
                  component: () => import("@/view/pages/masters/rooms/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Ruangan`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/rooms/add',
                  name: "rooms-add",
                  component: () => import("@/view/pages/masters/rooms/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Ruangan`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/rooms/edit/:id',
                  name: "rooms-edit",
                  component: () => import("@/view/pages/masters/rooms/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Ruangan`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'rooms-detail',
                  component: () => import('@/view/pages/masters/rooms/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Ruangan`,
                    access_right: '1801'
                  },
                }
              ]
            },
            {
              path: '/masters/warehouses',
              name: "warehouses",
              redirect: "/masters/warehouses/list",
              component: () => import("@/view/pages/masters/warehouses/Warehouse.vue"),
              children: [
                {
                  path: '/masters/warehouses/list',
                  name: "warehouses-list",
                  component: () => import("@/view/pages/masters/warehouses/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Gudang`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/warehouses/add',
                  name: "warehouses-add",
                  component: () => import("@/view/pages/masters/warehouses/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Gudang`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/warehouses/edit/:id',
                  name: "warehouses-edit",
                  component: () => import("@/view/pages/masters/warehouses/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Gudang`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'warehouses-detail',
                  component: () => import('@/view/pages/masters/warehouses/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Gudang`,
                    access_right: '1801'
                  },
                }
              ]
            },
            {
              path: '/masters/lab-templates',
              name: "lab-templates",
              redirect: "/masters/lab-templates/list",
              component: () => import("@/view/pages/masters/lab-templates/LabTemplate.vue"),
              children: [
                {
                  path: '/masters/lab-templates/list',
                  name: "lab-templates-list",
                  component: () => import("@/view/pages/masters/lab-templates/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Template Laporan`,
                    access_right: '1105'
                  },
                },
                {
                  path: '/masters/lab-templates/add',
                  name: "lab-templates-add",
                  component: () => import("@/view/pages/masters/lab-templates/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Template Laporan`,
                    access_right: '1105'
                  },
                },
                {
                  path: '/masters/lab-templates/edit/:id',
                  name: "lab-templates-edit",
                  component: () => import("@/view/pages/masters/lab-templates/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Template Laporan`,
                    access_right: '1105'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'lab-templates-detail',
                  component: () => import('@/view/pages/masters/lab-templates/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Template Laporan`,
                    access_right: '1105'
                  },
                }
              ]
            },
            {
              path: '/masters/fields',
              name: "fields",
              redirect: "/masters/fields/list",
              component: () => import("@/view/pages/masters/fields/Field.vue"),
              children: [
                {
                  path: '/masters/fields/list',
                  name: "fields-list",
                  component: () => import("@/view/pages/masters/fields/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Template Laporan`,
                    access_right: '1106'
                  },
                },
                {
                  path: '/masters/fields/add',
                  name: "fields-add",
                  component: () => import("@/view/pages/masters/fields/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Template Laporan`,
                    access_right: '1107'
                  },
                },
                {
                  path: '/masters/fields/edit/:id',
                  name: "fields-edit",
                  component: () => import("@/view/pages/masters/fields/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Template Laporan`,
                    access_right: '1107'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'fields-detail',
                  component: () => import('@/view/pages/masters/fields/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Template Laporan`,
                    access_right: '1106'
                  },
                }
              ]
            },
            {
              path: '/masters/medicine-categories',
              name: "medicine-categories",
              redirect: "/masters/medicine-categories/list",
              component: () => import("@/view/pages/masters/medicine-categories/MedicineCategory.vue"),
              children: [
                {
                  path: '/masters/medicine-categories/list',
                  name: "medicine-categories-list",
                  component: () => import("@/view/pages/masters/medicine-categories/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Kategori Obat`,
                    access_right: '9003'
                  },
                },
                {
                  path: '/masters/medicine-categories/add',
                  name: "medicine-categories-add",
                  component: () => import("@/view/pages/masters/medicine-categories/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Kategori Obat`,
                    access_right: '9008'
                  },
                },
                {
                  path: '/masters/medicine-categories/edit/:id',
                  name: "medicine-categories-edit",
                  component: () => import("@/view/pages/masters/medicine-categories/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Kategori Obat`,
                    access_right: '9008'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'medicine-categories-detail',
                  component: () => import('@/view/pages/masters/medicine-categories/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Kategori Obat`,
                    access_right: '9003'
                  },
                }
              ]
            },
            // {
            //   path: '/masters/inventory-categories',
            //   name: "inventory-categories",
            //   redirect: "/masters/inventory-categories/list",
            //   component: () => import("@/view/pages/masters/inventory-categories/MedicineCategory.vue"),
            //   children: [
            //     {
            //       path: '/masters/inventory-categories/list',
            //       name: "inventory-categories-list",
            //       component: () => import("@/view/pages/masters/inventory-categories/List.vue"),
            //       meta: {
            //         title: `${conf == null ? "Medhop" : conf.clinic_name } | Master - Kategori Inventori`,
            //         access_right: '9003'
            //       },
            //     },
            //     {
            //       path: '/masters/inventory-categories/add',
            //       name: "inventory-categories-add",
            //       component: () => import("@/view/pages/masters/inventory-categories/Add.vue"),
            //       meta: {
            //         title: `${conf == null ? "Medhop" : conf.clinic_name } | Master - Kategori Inventori',                ` 
            //         access_right: '9008'
            //       },
            //     },
            //     {
            //       path: '/masters/inventory-categories/edit/:id',
            //       name: "inventory-categories-edit",
            //       component: () => import("@/view/pages/masters/inventory-categories/Edit.vue"),
            //       meta: {
            //         title: `${conf == null ? "Medhop" : conf.clinic_name } | Master - Kategori Inventori',                ` 
            //         access_right: '9008'
            //       },
            //     },
            //     {
            //       path: 'detail/:id',
            //       name: 'inventory-categories-detail',
            //       component: () => import('@/view/pages/masters/inventory-categories/Detail.vue'),
            //       meta: {
            //         title: `${conf == null ? "Medhop" : conf.clinic_name } | Master - Kategori Inventori`,
            //         access_right: '9003'
            //       },
            //     }
            //   ]
            // },
            {
              path: '/masters/payment-methods',
              name: "payment-method",
              redirect: "/masters/payment-methods/list",
              component: () => import("@/view/pages/masters/payment-methods/PaymentMethod.vue"),
              children: [
                {
                  path: '/masters/payment-methods/list',
                  name: "payment-method-list",
                  component: () => import("@/view/pages/masters/payment-methods/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Metode Pembayaran`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/payment-methods/add',
                  name: "payment-method-add",
                  component: () => import("@/view/pages/masters/payment-methods/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Metode Pembayaran`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/payment-methods/edit/:id',
                  name: "payment-method-edit",
                  component: () => import("@/view/pages/masters/payment-methods/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Metode Pembayaran`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: "payment-method-detail",
                  component: () => import("@/view/pages/masters/payment-methods/Detail.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Metode Pembayaran`,
                    access_right: '1801'
                  },
                }
              ]
            },
            {
              path: '/masters/payment-statuses',
              name: "payment-statuses",
              redirect: "/masters/payment-statuses/list",
              component: () => import("@/view/pages/masters/payment-statuses/PaymentStatuses.vue"),
              children: [
                {
                  path: '/masters/payment-statuses/list',
                  name: "payment-status-list",
                  component: () => import("@/view/pages/masters/payment-statuses/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Status Pembayaran`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/payment-statuses/add',
                  name: "payment-status-add",
                  component: () => import("@/view/pages/masters/payment-statuses/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Status Pembayaran`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/payment-statuses/edit/:id',
                  name: "payment-status-edit",
                  component: () => import("@/view/pages/masters/payment-statuses/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Status Pembayaran`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: "payment-status-detail",
                  component: () => import("@/view/pages/masters/payment-statuses/Detail.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Status Pembayaran`,
                    access_right: '1801'
                  },
                },
                {
                  path: 'payment-history/:id',
                  name: "payment-status-detail",
                  component: () => import("@/view/pages/patients/PaymentHistory.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Riwayat Pembayaran`,
                    access_right: '1801'
                  },
                }
              ]
            },
            {
              path: '/masters/departemens',
              name: "departemens",
              redirect: "/masters/departemens/list",
              component: () => import("@/view/pages/masters/departemens/Departemens.vue"),
              children: [
                {
                  path: '/masters/departemens/list',
                  name: "departemens-list",
                  component: () => import("@/view/pages/masters/departemens/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Departemen`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/departemens/add',
                  name: "departemens-add",
                  component: () => import("@/view/pages/masters/departemens/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Departemen`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/departemens/edit/:id',
                  name: "departemens-edit",
                  component: () => import("@/view/pages/masters/departemens/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Departemen`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: "departemens-detail",
                  component: () => import("@/view/pages/masters/departemens/Detail.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Departemen`,
                    access_right: '1801'
                  },
                },
              ]
            },
            {
              path: '/masters/positions',
              name: "positions",
              redirect: "/masters/positions/list",
              component: () => import("@/view/pages/masters/positions/Position.vue"),
              children: [
                {
                  path: '/masters/positions/list',
                  name: "positions-list",
                  component: () => import("@/view/pages/masters/positions/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Jabatan`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/positions/add',
                  name: "positions-add",
                  component: () => import("@/view/pages/masters/positions/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Jabatan`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/positions/edit/:id',
                  name: "positions-edit",
                  component: () => import("@/view/pages/masters/positions/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Jabatan`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: "positions-detail",
                  component: () => import("@/view/pages/masters/positions/Detail.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Jabatan`,
                    access_right: '1801'
                  },
                },
              ]
            },
            {
              path: '/masters/bank-accounts',
              name: "bank-accounts",
              redirect: "/masters/bank-accounts/list",
              component: () => import("@/view/pages/masters/bank-accounts/BankAccount.vue"),
              children: [
                {
                  path: '/masters/bank-accounts/list',
                  name: "bank-accounts-list",
                  component: () => import("@/view/pages/masters/bank-accounts/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Rekening`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/bank-accounts/add',
                  name: "bank-accounts-add",
                  component: () => import("@/view/pages/masters/bank-accounts/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Rekening`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/bank-accounts/edit/:id',
                  name: "bank-accounts-edit",
                  component: () => import("@/view/pages/masters/bank-accounts/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Rekening`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: "bank-accounts-detail",
                  component: () => import("@/view/pages/masters/bank-accounts/Detail.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Rekening`,
                    access_right: '1801'
                  },
                },
              ]
            },
            {
              path: '/masters/bank-account-histories/:id',
              name: "bank-account-histories",
              redirect: "/masters/bank-account-histories/list/:id",
              component: () => import("@/view/pages/masters/bank-account-histories/BankAccount.vue"),
              children: [
                {
                  path: '/masters/bank-account-histories/:id',
                  name: "bank-account-histories-list",
                  component: () => import("@/view/pages/masters/bank-account-histories/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Riwayat Rekening`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/bank-account-histories/add',
                  name: "bank-account-histories-add",
                  component: () => import("@/view/pages/masters/bank-account-histories/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Riwayat Rekening`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/bank-account-histories/edit/:id',
                  name: "bank-account-histories-edit",
                  component: () => import("@/view/pages/masters/bank-account-histories/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Riwayat Rekening`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: "bank-account-histories-detail",
                  component: () => import("@/view/pages/masters/bank-account-histories/Detail.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Riwayat Rekening`,
                    access_right: '1801'
                  },
                },
              ]
            },
            {
              path: '/masters/remuneration-types',
              name: "remuneration-types",
              redirect: "/masters/remuneration-types/list",
              component: () => import("@/view/pages/masters/remuneration-types/RemunerationTypes.vue"),
              children: [
                {
                  path: '/masters/remuneration-types/list',
                  name: "remuneration-types-list",
                  component: () => import("@/view/pages/masters/remuneration-types/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Tipe Penggajian`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/remuneration-types/add',
                  name: "remuneration-types-add",
                  component: () => import("@/view/pages/masters/remuneration-types/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Tipe Penggajian`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/remuneration-types/edit/:id',
                  name: "remuneration-types-edit",
                  component: () => import("@/view/pages/masters/remuneration-types/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Tipe Penggajian`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: "remuneration-types-detail",
                  component: () => import("@/view/pages/masters/remuneration-types/Detail.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Tipe Penggajian`,
                    access_right: '1801'
                  },
                },
              ]
            },
            {
              path: '/masters/remunerations',
              name: "remunerations",
              redirect: "/masters/remunerations/list",
              component: () => import("@/view/pages/masters/remunerations/Remuneration.vue"),
              children: [
                {
                  path: '/masters/remunerations/list',
                  name: "remunerations-list",
                  component: () => import("@/view/pages/masters/remunerations/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Penggajian`,
                    access_right: '7003'
                  },
                },
                {
                  path: '/masters/remunerations/add',
                  name: "remunerations-add",
                  component: () => import("@/view/pages/masters/remunerations/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Penggajian`,
                    access_right: '7004'
                  },
                },
                {
                  path: '/masters/remunerations/edit/:id',
                  name: "remunerations-edit",
                  component: () => import("@/view/pages/masters/remunerations/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Penggajian`,
                    access_right: '7004'
                  },
                },
                {
                  path: 'detail/:id',
                  name: "remunerations-detail",
                  component: () => import("@/view/pages/masters/remunerations/Detail.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Penggajian`,
                    access_right: '7003'
                  },
                },
              ]
            },
            {
              path: '/masters/payment-category-types',
              name: "payment-category-types",
              redirect: "/masters/payment-category-types/list",
              component: () => import("@/view/pages/masters/payment-category-types/PaymentCategoryTypes.vue"),
              children: [
                {
                  path: '/masters/payment-category-types/list',
                  name: "payment-category-types-list",
                  component: () => import("@/view/pages/masters/payment-category-types/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Tipe Produk Layanan`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/payment-category-types/add',
                  name: "payment-category-types-add",
                  component: () => import("@/view/pages/masters/payment-category-types/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Tipe Produk Layanan`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/payment-category-types/edit/:id',
                  name: "payment-category-types-edit",
                  component: () => import("@/view/pages/masters/payment-category-types/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Tipe Produk Layanan`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: "payment-category-types-detail",
                  component: () => import("@/view/pages/masters/payment-category-types/Detail.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Tipe Produk Layanan`,
                    access_right: '1801'
                  },
                }
              ]
            },
            {
              path: '/masters/announcement-categories',
              name: "announcement-categories",
              redirect: "/masters/announcement-categories/list",
              component: () => import("@/view/pages/masters/announcement-categories/AnnouncementCategory.vue"),
              children: [
                {
                  path: '/masters/announcement-categories/list',
                  name: "announcement-categories-list",
                  component: () => import("@/view/pages/masters/announcement-categories/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Kategori Pengumuman`,
                    access_right: '1801'
                  },
                },
                {
                  path: '/masters/announcement-categories/add',
                  name: "announcement-categories-add",
                  component: () => import("@/view/pages/masters/announcement-categories/Add.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Kategori Pengumuman`,
                    access_right: '1802'
                  },
                },
                {
                  path: '/masters/announcement-categories/edit/:id',
                  name: "announcement-categories-edit",
                  component: () => import("@/view/pages/masters/announcement-categories/Edit.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Kategori Pengumuman`,
                    access_right: '1802'
                  },
                },
                {
                  path: 'detail/:id',
                  name: "announcement-categories-detail",
                  component: () => import("@/view/pages/masters/announcement-categories/Detail.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Kategori Pengumuman`,
                    access_right: '1801'
                  },
                }
              ]
            },
            {
              path: '/masters/coa-categories',
              name: "coa-categories",
              redirect: "/masters/coa-categories/list",
              component: () => import("@/view/pages/masters/coa-categories/CoaCategory.vue"),
              children: [
                {
                  path: '/masters/coa-categories/list',
                  name: "coa-categories-list",
                  component: () => import("@/view/pages/masters/coa-categories/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Kategori COA`,
                    access_right: '1801'
                  },
                },
                // {
                //   path: '/masters/coa-categories/add',
                //   name: "coa-categories-add",
                //   component: () => import("@/view/pages/masters/coa-categories/Add.vue"),
                //   meta: {
                //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Master - CO`'
                //   },
                // },
                // {
                //   path: '/masters/coa-categories/edit/:id',
                //   name: "coa-categories-edit",
                //   component: () => import("@/view/pages/masters/coa-categories/Edit.vue"),
                //   meta: {
                //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Master - CO`'
                //   },
                // },
                // {
                //   path: 'detail/:id',
                //   name: "coa-categories-detail",
                //   component: () => import("@/view/pages/masters/coa-categories/Detail.vue"),
                //   meta: {
                //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Master - CO`'
                //   },
                // }
              ]
            },
            {
              path: '/masters/cash-flows',
              name: "cash-flows",
              redirect: "/masters/cash-flows/list",
              component: () => import("@/view/pages/masters/cash-flows/CashFlow.vue"),
              children: [
                {
                  path: '/masters/cash-flows/list',
                  name: "cash-flows-list",
                  component: () => import("@/view/pages/masters/cash-flows/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Cash Flow`,
                    access_right: '1801'
                  },
                },
                // {
                //   path: '/masters/coa-categories/add',
                //   name: "coa-categories-add",
                //   component: () => import("@/view/pages/masters/coa-categories/Add.vue"),
                //   meta: {
                //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Master - CO`'
                //   },
                // },
                // {
                //   path: '/masters/coa-categories/edit/:id',
                //   name: "coa-categories-edit",
                //   component: () => import("@/view/pages/masters/coa-categories/Edit.vue"),
                //   meta: {
                //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Master - CO`'
                //   },
                // },
                // {
                //   path: 'detail/:id',
                //   name: "coa-categories-detail",
                //   component: () => import("@/view/pages/masters/coa-categories/Detail.vue"),
                //   meta: {
                //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Master - CO`'
                //   },
                // }
              ]
            },
            {
              path: '/masters/sub-ledgers',
              name: "sub-ledgers",
              redirect: "/masters/sub-ledgers/list",
              component: () => import("@/view/pages/masters/sub-ledgers/SubLedger.vue"),
              children: [
                {
                  path: '/masters/sub-ledgers/list',
                  name: "sub-ledgers-list",
                  component: () => import("@/view/pages/masters/sub-ledgers/List.vue"),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Master - Sub Ledger`,
                    access_right: '1801'
                  },
                },
                // {
                //   path: '/masters/coa-categories/add',
                //   name: "coa-categories-add",
                //   component: () => import("@/view/pages/masters/coa-categories/Add.vue"),
                //   meta: {
                //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Master - CO`'
                //   },
                // },
                // {
                //   path: '/masters/coa-categories/edit/:id',
                //   name: "coa-categories-edit",
                //   component: () => import("@/view/pages/masters/coa-categories/Edit.vue"),
                //   meta: {
                //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Master - CO`'
                //   },
                // },
                // {
                //   path: 'detail/:id',
                //   name: "coa-categories-detail",
                //   component: () => import("@/view/pages/masters/coa-categories/Detail.vue"),
                //   meta: {
                //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Master - CO`'
                //   },
                // }
              ]
            },
          ]
        },

        // pharmacy        
        {
          path: "/pharmacy",
          name: "pharmacy",
          redirect: 'pharmacy/dashboard',
          component: () => import('@/view/pages/pharmacy/Pharmacy.vue'),
          children: [
            {
              path: '/pharmacy/dashboard',
              name: 'pharmacy-dashboard',
              component: () => import('@/view/pages/pharmacy/BlankBoard.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} - Apotik | Dashboard`,
                access_right: '1401'
              },
            },
            {
              path: 'sales',
              name: 'pharmacy-sales',
              component: () => import('@/view/pages/pharmacy/pharmacy-orders/List.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} - Apotik | Penjualan`,
                access_right: '1401'
              }
            },
            {
              path: 'sales/detail/:id',
              name: 'pharmacy-sales-detail',
              component: () => import('@/view/pages/pharmacy/pharmacy-orders/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} - Apotik | Detail Penjualan`,
                access_right: '1401'
              }
            },
            {
              path: 'cashier',
              name: 'pharmacy-cashier',
              component: () => import('@/view/pages/pharmacy/Cashier.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} - Apotik | Kasir`,
                access_right: '1402'
              }
            },
            {
              path: '/pharmacy/pharmacy-expense-categories',
              name: 'pharmacy-expense-categories',
              component: () => import('@/view/pages/pharmacy/pharmacy-expense-categories/PharmacyExpenseCategory.vue'),
              redirect: '/pharmacy-expense-categories-list',
              children: [
                {
                  path: 'list',
                  name: 'pharmacy-pharmacy-expense-categories-list',
                  component: () => import('@/view/pages/pharmacy/pharmacy-expense-categories/List.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Kategori Pengeluaran Apotek`,
                    access_right: '1405'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'pharmacy-pharmacy-expense-categories-detail',
                  component: () => import('@/view/pages/pharmacy/pharmacy-expense-categories/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Kategori Pengeluaran Apotek`,
                    access_right: '1405'
                  },
                },
              ]
            },
            {
              path: '/pharmacy/pharmacy-expenses',
              name: 'pharmacy-expenses',
              component: () => import('@/view/pages/pharmacy/pharmacy-expenses/PharmacyExpense.vue'),
              redirect: '/pharmacy-expenses-list',
              children: [
                {
                  path: 'list',
                  name: 'pharmacy-pharmacy-expenses-list',
                  component: () => import('@/view/pages/pharmacy/pharmacy-expenses/List.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Pengeluaran Apotek`,
                    access_right: '1403'
                  },
                },
                {
                  path: 'add',
                  name: 'pharmacy-pharmacy-expenses-add',
                  component: () => import('@/view/pages/pharmacy/pharmacy-expenses/Add.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Pengeluaran Apotek`,
                    access_right: '1404'
                  },
                },
                {
                  path: 'edit/:id',
                  name: 'pharmacy-pharmacy-expenses-edit',
                  component: () => import('@/view/pages/pharmacy/pharmacy-expenses/Edit.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Pengeluaran Apotek`,
                    access_right: '1404'
                  },
                },
                {
                  path: 'detail/:id',
                  name: 'pharmacy-pharmacy-expenses-detail',
                  component: () => import('@/view/pages/pharmacy/pharmacy-expenses/Detail.vue'),
                  meta: {
                    title: `${conf == null ? "Medhop" : conf.clinic_name} | Pengeluaran Apotek`,
                    access_right: '1403'
                  },
                },
              ]
            },
          ]
        },

        // profile
        {
          path: "/profile",
          name: "profile",
          redirect: 'profile/show',
          component: () => import('@/view/pages/profiles/Profiles.vue'),
          children: [
            {
              path: 'show',
              name: 'profile-show',
              component: () => import('@/view/pages/profiles/Show.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Profil`
              },
            },
            {
              path: 'change-password',
              name: 'profile-change-password',
              component: () => import('@/view/pages/profiles/ChangePassword.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Profil`
              },
            },

            {
              path: 'change-profile',
              name: 'profile-change-profile',
              component: () => import('@/view/pages/profiles/ChangeProfile.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Profil`
              },
            }
          ]
        },

        // metronic default
        {
          path: "/custom-pages",
          name: "custom-pages",
          component: () => import("@/view/pages/custom_pages/CustomPages.vue"),
          children: [
            {
              path: "profile",
              name: "profile",
              component: () => import("@/view/pages/custom_pages/Profile.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Custom Page`
              },
            },
          ],
        },

        // user management
        {
          path: "/user-managements",
          name: "user-managements",
          component: () => import("@/view/pages/user-managements/UserManagement.vue"),
          children: [
            {
              path: "user-access",
              name: "user-access",
              component: () => import("@/view/pages/user-managements/UserAccess.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Manajemen Pengguna`,
                access_right: '1901'
              },
            },
            {
              path: "position-access",
              name: "position-access",
              component: () => import("@/view/pages/user-managements/PositionAccess.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Manajemen Pengguna`,
                access_right: '1902'
              },
            },
          ],
        },

        // settings
        {
          path: "/settings",
          redirect: "/settings/edit",
          meta: {
            access_right: '2901'
          },
          component: () => import("@/view/pages/settings/Setting.vue"),
          children: [
            {
              path: "edit",
              name: "edit",
              component: () => import("@/view/pages/settings/Edit.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Konfigurasi - Edit`,
                access_right: '2901'
              },
            },
          ]
        },

        // billings
        {
          path: "/billings",
          name: "billings",
          redirect: "/billings/list",
          component: () => import("@/view/pages/billings/Billings.vue"),
          children: [
            {
              path: "list",
              name: "list",
              component: () => import("@/view/pages/billings/List.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Daftar Langganan`,
                // access_right: '1901'
              },
            },
            {
              path: 'detail/:id',
              name: 'billing-detail',
              component: () => import('@/view/pages/billings/Detail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Detail Langganan`,
                // access_right: '1403'
              },
            },
            {
              path: 'invoice/:id',
              name: 'billing-detail',
              component: () => import('@/view/pages/billings/Invoice.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Invoice Langganan`,
                // access_right: '1403'
              },
            },

          ],
        },

        // info clinic
        {
          path: "/info-clinic",
          name: "info-clinic",
          redirect: "/info-clinic/detail",
          component: () => import("@/view/pages/billings/Billings.vue"),
          children: [
            {
              path: "detail",
              name: "detail",
              component: () => import("@/view/pages/billings/InfoClinic.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Info Klinik`,
                // access_right: '1901'
              },
            },

          ],
        },


        {
          path: '/subscribe-confirmation',
          name: 'subscribe-confirmation',
          meta: {
            title: `${conf == null ? "Medhop" : conf.clinic_name} | Konfirmasi Berlangganan`
          },
          component: () => import('@/view/pages/confirmation/Subscribe-Confirmation.vue')
        },

        // Subscribe
        {
          path: '/subscribe',
          redirect: 'subscribe/checkout',
          component: () => import('@/view/pages/subscribes/Subscribe.vue'),
          children: [
            {
              path: 'checkout',
              name: 'checkout',
              component: () => import('@/view/pages/subscribes/Checkout.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Checkout`,
                // access_right: '6003'
              },
            },
            {
              path: 'payment',
              name: 'payment',
              component: () => import('@/view/pages/subscribes/Invoice.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Pembayaran`,
                // access_right: '6003'
              },
            },
            {
              path: 'package',
              name: 'package',
              component: () => import('@/view/pages/subscribes/Package.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Paket Langganan`,
                // access_right: '6003'
              },
            },

            // {
            //   path: 'add',
            //   name: 'subscribe-add',
            //   component: () => import('@/view/pages/subscribe/Add.vue'),
            //   meta: {
            //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Produk Layanan`,
            //     access_right: '6009'
            //   },
            // },
            // {
            //   path: 'edit/:id',
            //   name: 'subscribe-edit',
            //   component: () => import('@/view/pages/subscribe/Edit.vue'),
            //   meta: {
            //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Produk Layanan`,             
            //     access_right: '6009'
            //   },
            // },
            // {
            //   path: 'detail/:id',
            //   name: 'subscribe-detail',
            //   component: () => import('@/view/pages/subscribe/Detail.vue'),
            //   meta: {
            //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Produk Layanan`,
            //     access_right: '6003'
            //   },
            // },
            // {
            //   path: 'import',
            //   name: 'subscribe-import',
            //   component: () => import('@/view/pages/subscribe/Import.vue'),
            //   meta: {
            //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Produk Layanan`,
            //     access_right: '6009'
            //   },
            // },
            // {
            //   path: 'add-doctor-commision/:id',
            //   name: 'subscribe-add-doctor-commision',
            //   component: () => import('@/view/pages/subscribe/AddDoctorCommision.vue'),
            //   meta: {
            //     title: `${conf == null ? "Medhop" : conf.clinic_name } | Tambah Komisi Dokter`,
            //     access_right: '6003'
            //   },
            // },
          ]
        },

        // website setting        
        {
          path: "/website",
          redirect: "/website/setting",
          meta: {
            access_right: '1701'
          },
          component: () => import("@/view/pages/websites/Websites.vue"),
          children: [
            {
              path: "setting/:id?",
              name: "website-setting",
              component: () => import("@/view/pages/websites/Setting.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Website - Setelan`,
                access_right: '1701'
              },
            },
            {
              path: "slide",
              name: "website-slide",
              component: () => import("@/view/pages/websites/Slide.vue"),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Website - Slide`,
                access_right: '1701'
              },
            },
            {
              path: 'service',
              name: 'website-service',
              component: () => import('@/view/pages/websites/Service.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Website - Layanan`,
                access_right: '1701'
              },
            },
            {
              path: 'doctor',
              name: 'website-doctor',
              component: () => import('@/view/pages/websites/Doctor.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Website - Dokter Spesialis`,
                access_right: '1701'
              },
            },
            {
              path: 'post-category',
              name: 'website-post-category',
              component: () => import('@/view/pages/websites/PostCategory.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Website - Kategori Berita`,
                access_right: '1701'
              },
            },
            {
              path: 'post-category/add',
              name: 'website-post-category',
              component: () => import('@/view/pages/websites/PostCategoryAdd.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Website - Kategori Berita`,
                access_right: '1701'
              },
            },
            {
              path: 'post-category/edit/:id',
              name: 'website-post-category',
              component: () => import('@/view/pages/websites/PostCategoryEdit.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Website - Kategori Berita`,
                access_right: '1701'
              },
            },
            {
              path: 'post-category/detail/:id',
              name: 'website-post-category',
              component: () => import('@/view/pages/websites/PostCategoryDetail.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Website - Kategori Berita`,
                access_right: '1701'
              },
            },
            {
              path: 'posts',
              name: 'website-posts',
              component: () => import('@/view/pages/websites/Posts.vue'),
              meta: {
                title: `${conf == null ? "Medhop" : conf.clinic_name} | Website - Berita`,
                access_right: '1701'
              },
            },
          ],
        },
        {
          path: '/help',
          name: 'help',
          component: () => import('@/view/pages/help/Help.vue'),
          meta: {
            title: `${conf == null ? "Medhop" : conf.clinic_name} | Bantuan`
          },
        },
        {
          path: '/guide',
          name: 'guide',
          component: () => import('@/view/pages/help/Guide.vue'),
          meta: {
            title: `${conf == null ? "Medhop" : conf.clinic_name} | Tutorial`
          },
        },
      ],
    },

    // login
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
          meta: {
            title: `Medhop | Login`
          }
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
          meta: {
            title: `Medhop | Register`
          }
        },
      ],
    },

    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/view/pages/profiles/ResetPassword.vue'),
      meta: {
        title: `${conf == null ? "Medhop" : conf.clinic_name} | Reset Password`
      },
    },

    // redirect to 404
    {
      path: "*",
      redirect: "/404",
    },

    // 404
    {
      path: "/404",
      name: "404",
      meta: {
        title: `${conf == null ? "Medhop" : conf.clinic_name} | Page Not Found`
      },
      component: () => import("@/view/pages/error/Error-1.vue"),
    },

    // 503
    {
      path: '/access-forbidden',
      name: 'access-forbidden',
      meta: {
        title: `${conf == null ? "Medhop" : conf.clinic_name} | Access Forbidden`
      },
      component: () => import('@/view/pages/error/Error-7.vue')
    },

  ],
})

// Route Middleware
router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.access_right)) {
    let localStorageAccessRight = JSON.parse(localStorage.getItem('access_right_display'))
    if (localStorageAccessRight != null) {
      let result = false
      for (let i = 0; i < localStorageAccessRight.length; i++) {
        result = false
        if (localStorageAccessRight[i] == to.meta.access_right) {
          result = true
          break
        }
      }
      if (result) {
        next()
      } else {
        next('/access-forbidden')
      }
    } else {
      next()
    }
  } else {
    next()
  }

})

export default router;